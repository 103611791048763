@import "../../styles/helpers";


.custom-button.primary {
  background-color: $red;
  transition: background-color 0.2s linear, color 0.2s linear;
  color: #f2f3f5;
  width: 60%;
  margin: 1rem 0.5rem 0 0.5rem;
  padding: 0.5rem 0.75rem;
  -webkit-transition: color 0.2s ease, background-color 0.2s ease;
  transition: background-color 0.2s linear, color 0.2s linear;
  letter-spacing: 2px;
  text-align: center;
  font-family: Arial,sans-serif;
  font-weight: 700;
  font-size: 1.15rem;
  cursor: pointer;
  @include d {
    font-size: 0.9rem; }
  &:hover button {
    background-color: #f2f3f5;
    color: $red; }
  &:hover .wallet-adapter-button.wallet-adapter-button-trigger {
    background-color: transparent;
    color: $red; }
  .wallet-adapter-button.wallet-adapter-button-trigger {
    height: auto;
    color: $white;
    background: rgba(0, 0, 0, 0.61);
    font-family: Arial,sans-serif;
    background-color: transparent;
    letter-spacing: 2px;
    border-radius: 0;
    width: 100%;
    transition: background-color 0.2s linear, color 0.2s linear;
    &:hover {
      background: transparent; } }

  .wallet-adapter-button {
    transition: background-color 0.2s linear, color 0.2s linear; }

  .wallet-adapter-button:not([disabled]):hover {
      background-image: transparent !important; } }



.custom-button.primary button {
  background-color: $red;
  transition: background-color 0.2s linear, color 0.2s linear;
  color: #f2f3f5;
  -webkit-transition: color 0.2s ease, background-color 0.2s ease;
  transition: background-color 0.2s linear, color 0.2s linear;
  letter-spacing: 2px;
  text-align: center;
  font-family: Arial,sans-serif;
  font-weight: 700;
  font-size: 1.15rem;
  cursor: pointer;
  @include d {
    font-size: 0.9rem; } }

.custom-button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 40px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  transform: translate(0);
  transition: background-color 0.2s linear, color 0.2s linear;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  text-decoration: none; }

.corner-black.top-left.small {
  transform: translate(-92%, -54%) rotate(45deg);
  z-index: 2;
  transform: translate(-70%, -54%) rotate(45deg); }


.corner-black.small {
  width: 20px;
  height: 20px; }

.corner-black.top-left {
  position: absolute;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%) rotate(45deg); }

.custom-button.primary:hover {
  background-color: #f2f3f5;
  color: $red; }

.corner-black {
  width: 40px;
  height: 40px;
  background-color: $secondary-bg;
  transform: rotate(45deg); }

.corner-black.bottom-right {
  position: absolute;
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%) rotate(45deg); }


.custom-button-text-2 {
  position: relative;
  z-index: 3; }

.custom-button-hover {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: -10px -20px;
  transform: translate(-100%); }

