.mb-10 {
  margin-bottom: 10px;
  color: #fff;
}
.section {
  position: relative;
  padding-top: 100px;
  background-image: none;
  background-position: 0 0;
}
.section.footer {
  padding-top: 20px;
}
.text-white-opacity {
  color: rgba(242, 243, 245, 0.6);
  font-size: 18px;
}
.subscribe-socials.community {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-social {
  display: block;
  width: 25px;
  margin-bottom: 15px;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}
.footer-social:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.footer-social.community {
  display: inline-block;
  width: 30px;
  margin-right: 10px;
  margin-bottom: 0;
  margin-left: 10px;
}
.footer-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.divider {
  height: 1px;
  background-color: rgba(242, 243, 245, 0.07);
}
.footer-lonks-left {
  display: flex;
  align-items: center;
}
.mb-40 {
  margin-bottom: 40px !important;
  -webkit-filter: none;
  filter: none;
}
.image-10 {
  -webkit-filter: brightness(60%);
  filter: brightness(60%);
}
@media screen and (max-width: 991px) {
  .text-extra-small.align-left.text-white-opacity.mb-10 {
    font-size: 14px;
  }
  .container {
    padding-top: 57px;
    padding-bottom: 57px;
  }
  .mb-10 {
    font-size: 33px;
  }
  .section {
    padding-top: 60px;
  }
}
@media screen and (max-width: 767px) {
  .section.footer {
    padding-bottom: 20px;
  }
  .subscribe-socials {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .footer-social {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .footer-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-lonks-left {
    margin-bottom: 10px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-align: center;
  }
  .mb-40 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 479px) {
  .text-extra-small.align-left.text-white-opacity.mb-10 {
    text-align: center;
  }
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .mb-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
