@import "../../../styles/helpers";

.overviewItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%; }

.overviewContainer {
  margin: 0 10px 60px;
  margin-top: 2.5vw;
  padding-top: 50px; }

.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px; }

.galleryTitle {
  font-family: "Nine Tsuki", sans;
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 110px;
  color: #b94841;
  //border: 2px solid #360000 that border color
  text-align: center;
  letter-spacing: 0.4rem;
  font-size: 6vw;
  margin-bottom: 2vw;
  @include d {
    font-size: 7vw;
    margin-bottom: 2vw; }
  @include a {
    max-width: 80%;
    font-size: 12vw; } }

.galleryContainer {
  text-align: center; }

.galleryImage {
  width: 80%; }

.titleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 35px; }


.line {
  content: "";
  height: 0;
  border: 2px solid #b94841;
  width: 40%;
  margin-bottom: 10px; }

.overviewTitle {
  margin: 0 5% 10px;
  color: #b94841;
  font-size: 46px;
  font-family: "Nine Tsuki", sans;
  @include a {
    font-size: 46px; } }

.overviewItemContainer {
  width: 100%;
  background: rgba(255, 0, 0, 0.06);
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vw;
  flex: 0 0 calc(19% - 1%);
  margin: 1%;
  font-family: Arial, sans-serif;
  font-weight: 500;
  border-style: solid;
  border-width: 6px;
  border-color: #17161b;
  background-color: #292a2e;
  box-shadow: 0 0 0 1.5px #b94841;
  @include d {
    flex: 0 0 calc(33% - 6px); }
  @include m {
    flex: 0 0 calc(50% - 6px); }
  @include a {
    flex: 0 0 calc(100% - 6px); } }

.overviewItems {
  margin-bottom: 2vw;
  @include a {
    margin-bottom: 4vw; } }

.overviewItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.overviewItemContent {
  font-size: 30px;
  text-align: center;
  @include a {
    font-size: 20px;
    text-align: center; }
  & span {
    font-size: 23px;
    text-align: center;
    @include a {
      font-size: 12px;
      text-align: center; } } }

.overviewItemTitle, .overviewItemDescription {
  font-size: 17px;
  @include a {
    font-size: 12px; } }
