
// common styles
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./reset";
@import "./helpers";
@import "./common";

// global classes
@import "./blocks/wallet";
@import "./blocks/section";
@import "./blocks/container";
@import "./blocks/button";
@import "./blocks/title";
@import "./blocks/slick";
@import "./blocks/status";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: "wallet";
    src: url("./../fonts/wallet.ttf"); }

@font-face {
    font-family: "wallet-bold";
    src: url("./../fonts/WalletBold.ttf"); }

@font-face {
  font-family: 'Nine Tsuki';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/NineTsukiRegular.ttf') format('truetype'); }
