@import "./../../../styles/helpers";

.card {
  width: 100%;
  height: auto;
  padding: 0.25rem 0.5rem 0.5rem;
  border-radius: 0.5rem;
  position: relative;
  small {
    margin-bottom: 0.25rem; } }

.image {
  width: 100%; }

.bgUnselected {
  background-color: trasnparent;
  border: 1px solid $red;
  animation: disappear 500ms ease-in;
  transition: background-color 0.5s ease;
  cursor: pointer; }

.bgSelected {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid $red;
  animation: appear 500ms ease-in; }

.bgUnselected:hover {
  background-color: $red;
  border: 1px solid black; }

@keyframes appear {
  from {
    background-color: $red;
    border: 1px solid black; }
  to {
    background-color: trasnparent;
    border: 1px solid $red; } }

@keyframes disappear {
  from {
    background-color: trasnparent;
    border: 1px solid $red; }
  to {
    background-color: $red;
    border: 1px solid black; } }



.circle {
  border-radius: 50%;
  background-color: #4eff2b75;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 7px;
  top: 7px;
  opacity: 0;
  animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32); }

@media screen and (max-width: 576px) {
  .circle {
    width: 10px;
    height: 10px; } }

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5; }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0; } }

