@import "../../styles/helpers";

.stakeNfts {
  display: flex;
  margin: 0 auto;
  font-family: {}
  font-family: Arial,sans-serif;
  justify-content: space-between;
  padding: 0 1.7%;
  margin-bottom: 14px;
  @include a {
    flex-direction: column;
    width: 98%; } }

.filterSmall {
  width: 3%;
  min-width: 67px;
  @include a {
    width: 95%; } }

.main {
  width: 59%;
  border-left: 6px solid #b94841;
  background-color: $secondary-bg;
  box-shadow: 0 0 0 1px rgba(242, 243, 245, 0.07);
  padding: 20px;
  @include a {
    width: 100%; } }

.selected {
  width: 30%;
  min-width: 225px;
  @include a {
    width: calc(100% - 20px);
    padding-bottom: 2rem; } }

ul {
  display: flex;
  justify-content: center;
  align-items: center; }

.apr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 1.15rem;
    @include d {
      font-size: 0.9rem; } } }

.options {
  display: flex;
  justify-content: space-between;
  border-radius: 20px 20px;
  margin: 0 10px;
  color: $white;
  height: 74px; }

.logo {
  width: 3.5rem;
  margin-right: 1rem; }

.title {
  margin-right: 1rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.title h2 {
  font-size: 1.5rem;
  @include d {
    font-size: 1.10rem; } }

.title h3 {
  font-size: 1.15rem;
  @include d {
    font-size: 0.9rem; } }

.nftsBtn {
  color: $white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 0.375rem;
  padding: 0.75rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  &:hover {
    border: 1px solid rgba(47, 45, 56, 0.5); }
  &:focus {
    background-color: #1c114e;
    border: 1px solid rgb(100, 80, 188); } }

.nftsList {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 10px;
  color: $white;
  font-size: 0.6rem;
  & h1 {
    font-size: 1.5rem;
    @include d {
      font-size: 1.10rem; }
    @include a {
      font-size: 1rem; } } }

.buttonLight {
  background-color: $purple;
  color: $white;
  font-size: 1.5rem;
  @include d {
    font-size: 1.10rem; }
  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(100, 80, 188); } }

.buttonWhite {
  background-color: $white;
  color: $purple1;
  font-size: 1.5rem;
  @include d {
    font-size: 1.10rem; }
  &:hover {
    background-color: rgba(255, 255, 255, 0.85);
    border: black; } }

.buttonNfts {
  margin: 1rem 0.5rem 0 0.5rem;
  padding: 0.75rem 1rem;
  font-weight: 700;
  font-size: 1.15rem;
  @include d {
    font-size: 0.9rem; } }

// selected

.selected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin: 0;
  //border-top-right-radius: 40px 40px
  //border-bottom-right-radius: 40px 40px
  color: $white;
  border-right: 6px solid #b94841;
  background-color: $secondary-bg;
  box-shadow: 0 0 0 1px rgba(242, 243, 245, 0.07);
  @include a {
    border-right: 0px solid #b94841;
    border-left: 6px solid #b94841;
    margin: 10px 0 0;
    width: 100%; }
  & h1 {
    font-size: 1.75rem; }
  & h2 {
    font-size: 1.5rem;
    color: #c8bdf0;
    @include d {
      font-size: 1.10rem; } }
  & button {
    color: $white; } }

.selectButton {
  width: 85%;
  padding: 1.75rem 0;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(180deg, #1c114a, #0a061a);
  margin-bottom: 2.5rem;
  border: 1px solid rgb(24, 19, 44);
  &:hover {
    background: #0a061a; } }

.selectButtons {
  margin-top: 1rem; }

.mintButton {
  background-color: #1c114d;
  font-size: 1rem;
  margin-left: 1rem;
  width: 10rem;
  border-radius: 0.5rem;
  padding: 1rem 0;
  border: 1px solid rgb(24, 19, 44);
  &:hover {
    background: #0a061a; } }

// filter

.filterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: $white;
  background-color: rgba(21, 36, 62, 0.4);
  border: 1px solid rgba(148, 246, 201, 0.5);
  border-radius: 20px 20px;
  margin: 0 10px;
  //border-top-left-radius: 40px 40px
  //border-bottom-left-radius: 40px 40px
  @include a {
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  svg {
    font-size: 2rem; }
  & h2 {
    font-size: 1.5rem;
    @include d {
      font-size: 1.10rem; } } }

.filterBig {
  width: 15%;
  min-width: 150px;
  align-items: start;
  font-size: 1.25rem;
  @include a {
    width: calc(100% - 20px);
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 0.85rem; } }

.filter {
  height: 75px;
  padding: 0rem 0.75rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  @include d {
    font-size: 1.10rem; } }

.search {
  margin-top: 2rem;
  width: 98%;
  background-color: transparent;
  height: 3rem;
  color: $neutrals6;
  margin: 0.2rem;
  appearance: textfield;
  padding: 0 1rem;
  @include a {
    width: 98%; }
  &:focus {
    border: 1px solid $neutrals5;
    border-radius: 0.25rem; }
  &::-webkit-inner-spin-button {
    appearance: none; }
  &::placeholder {
    color: $neutrals6; } }

/* Hide scrollbar for IE, Edge and Firefox */
.search {
  -ms-overflow-style: none;
  scrollbar-width: none; }

.cards {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: min-content;
  min-height: 50vh;
  width: 100%;
  gap: 0.75rem;
  @include d {
    grid-template-columns: repeat(4, 1fr); }
  @include t {
    grid-template-columns: repeat(3, 1fr); }
  @include a {
    grid-template-columns: repeat(2, 1fr); } }

.cardsContainer {
  color: white;
  padding: 1rem;
  margin: 25px 10px 0;
  border-top: 1px solid #b94841;
  h2 {
    @include a {
      font-size: 1em; } } }

.nftsHidden {
  display: none;
  opacity: 0; }

.stakingTitle {
  vertical-align: middle;
  height: 74px;
  display: flex;
  align-items: center;
  & h2 {
    color: $white; } }

.stakingList {
  padding: 1rem 0;
  width: 80%;
  height: 26rem;
  margin-bottom: 2rem;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none; } }

.stakingList {
  -ms-overflow-style: none;
  scrollbar-width: none; }

.total, .exchangeRate {
  width: 77%;
  display: flex;
  justify-content: space-between; }

.exchangeRate {
  margin-bottom: 1rem; }

.buttonConnect {
  width: 60%;
  height: auto;
  padding: 15px 15px;
  color: $white;
  margin-bottom: 5px;
  background: rgba(0, 0, 0, 0.61);
  font-family: Arial,sans-serif;
  transition: color 0.75s;
  background-color: #b94841;
  -webkit-transition: color 0.2s ease, background-color 0.2s ease;
  transition: color 0.2s ease, background-color 0.2s ease;
  letter-spacing: 2px;
  &:focus, &:hover {
    background-color: #f2f3f5;
    color: #b94841; }


  &:disabled {
    background-color: #f2f3f5;
    color: #b94841; }

  &:disabled:hover {
    background-color: transparent; } }


.small {
  font-size: 1rem;
  @include d {
    font-size: 0.85rem; }
  @include a {
    font-size: 1rem; } }

.smaller {
  font-size: 0.85rem;
  @include d {
    font-size: 0.75rem; }
  @include a {
    font-size: 0.85rem; } }

.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  min-width: 320px; }

.stakedTitles {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.stakedButtons {
  display: flex;
  width: 260px;
  align-items: center;
  @include a {
    width: 65%;
    font-family: DM Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; }
  button {
    padding: 10px 2px;
    margin: 5px;
    margin-left: auto;
    @include a {
      border-radius: 12px;
      padding: 6px 4px;
      font-size: 12.5px !important;
      margin: 5px; } } }



// CHECKBOX



.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  @include d {
    font-size: 12px;
    display: flex;
    align-items: center; } }

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.controlIndicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: 0px;
  @include d {
    height: 15px;
    width: 15px; } }

.control:hover input ~ .controlIndicator,
.control input:focus ~ .controlIndicator {
  background: #cccccc; }


.control input:checked ~ .controlIndicator {
  background: #2aa1c0; }

.control:hover input:not([disabled]):checked ~ .controlIndicator,
.control input:checked:focus ~ .controlIndicator {
  background: #2aa1c0; }

.control input:disabled ~ .controlIndicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none; }

.controlIndicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none; }

.control input:checked ~ .controlIndicator:after {
  display: block; }

.controlCheckbox .controlIndicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  @include d {
    left: 6px;
    top: 3px;
    width: 2px;
    height: 6px; } }

.controlCheckbox input:disabled ~ .controlIndicator:after {
  border-color: #7b7b7b; }

.controlCheckbox .controlIndicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #2aa1c0;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0); }

@keyframes s-ripple {
  0% {
      transform: scale(0); }
  20% {
      transform: scale(1); }
  100% {
      opacity: 0;
      transform: scale(1); } }


@keyframes s-ripple-dup {
  0% {
      transform: scale(0); }
  30% {
      transform: scale(1); }
  60% {
      transform: scale(1); }
  100% {
      opacity: 0;
      transform: scale(1); } }


.controlCheckbox input + .controlIndicator::before {
  animation: s-ripple 250ms ease-out;
  position: absolute;
  left: -5px;
  top: -5px; }

.controlCheckbox input:checked + .controlIndicator::before {
  animation-name: s-ripple-dup; }

