

.wallet-adapter-modal-list {
    flex-direction: column !important;
    margin: 0;
    & li {
        width: 100%;
        margin: 0; } }


.wallet-adapter-button.wallet-adapter-modal-collapse-button {
    margin-top: 10px; }
