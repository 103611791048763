@import "../../../styles/helpers";

.toStakeImage {
  display: flex;
  align-items: center;
  padding: 2%; }

.toStakeImage img {
  width: 3rem; }

.toStake {
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid $red;
  padding: 0 8px 0 0;
  border-radius: 5px; }


.toStakeSmalls {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  @include d {
    font-size: 0.85rem; }
  @include a {
    font-size: 1rem; } }


.smallTitle {
  color: $neutrals6; }

.deleteStake button {
  font-size: 1.25rem;
  color: $neutrals6;
  &:hover {
    color: $white; } }

.stakeDescriptions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }


